import React, { useState } from 'react';
import { Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function PaymentsPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    amount: '',
    country: 'United Kingdom',
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.amount) {
      alert('Please enter an amount');
      return;
    }

    setIsProcessing(true);

    try {
      // Call the backend to create a Payment Link with after_completion redirect
      const response = await fetch(`${import.meta.env.VITE_API_URL}/create-payment-link`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: formData.amount, country: formData.country }),
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Failed to create payment link');
      }

      // Use the returned payment link URL
      window.location.href = data.url;
    } catch (error) {
      console.error('Payment error:', error);
      alert(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-indigo-900 pt-32 pb-12">
      <div className="max-w-3xl mx-auto px-4">
        <div className="bg-white/10 backdrop-blur-md rounded-lg shadow-lg p-8 border border-white/20">
          <div className="flex justify-between items-start mb-8">
            <div>
              <h1 className="text-3xl font-bold text-white mb-4">Billing information</h1>
              <p className="text-blue-100">For tax purposes, please fill out these fields.</p>
            </div>
            <div className="text-right">
              <div className="text-sm text-blue-100">Total</div>
              <div className="text-2xl font-bold text-white">£{formData.amount || '0.00'}</div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Amount Input */}
            <div>
              <label className="block text-sm font-medium text-white mb-1">
                Amount (GBP)
              </label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-md focus:ring-white focus:border-white text-white"
                required
                min="0.01"
                step="0.01"
              />
            </div>

            {/* Country Selection */}
            <div>
              <label className="block text-sm font-medium text-white mb-1">
                Country
              </label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-md focus:ring-white focus:border-white text-white"
              >
                <option value="United Kingdom">United Kingdom</option>
              </select>
            </div>

            <button
              type="submit"
              disabled={isProcessing}
              className="w-full bg-white/10 backdrop-blur-md border border-white/20 text-white py-3 px-6 rounded-md hover:bg-white/20 transition-colors flex items-center justify-center gap-2"
            >
              <Lock className="w-4 h-4" />
              {isProcessing ? 'Processing...' : 'Proceed to Payment'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}