import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

export function PaymentSuccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to homepage after 3 seconds
    const timer = setTimeout(() => {
      window.location.href = '/'; // Using window.location.href instead of navigate
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-indigo-900 pt-32 pb-12">
      <div className="max-w-2xl mx-auto px-4">
        <div className="bg-white/10 backdrop-blur-md rounded-lg shadow-lg p-8 border border-white/20 text-center">
          <div className="flex justify-center mb-6">
            <CheckCircle className="w-16 h-16 text-green-400" />
          </div>
          <h1 className="text-3xl font-bold text-white mb-4">Payment Successful!</h1>
          <p className="text-blue-100 mb-8">
            Thank you for your payment. You will receive a confirmation email shortly.
          </p>
          <p className="text-blue-100 mb-4">
            Redirecting to homepage in 3 seconds...
          </p>
          <Link
            to="/"
            className="inline-block bg-white/10 text-white px-6 py-3 rounded-md hover:bg-white/20 transition-colors"
          >
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
} 